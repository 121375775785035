<div
  class="floating fixed bottom-0 left-0 z-20 w-full max-xl:pb-2"
  [ngClass]="{
    'xl:bottom-[40px]': whatsAppCTAIsAbove,
    'z-50': menuIsOpen,
    hidden: !renderCheckPrices,
  }"
>
  <div
    class="container mx-auto flex justify-between px-4 max-xl:space-x-4 xl:relative"
  >
    <a
      class="btn btn-lg btn-secondary elevation-3 grow bg-primary-400 xl:hidden"
      [href]="'tel:' + phoneNumber"
      (click)="clickedOrderNow.emit()"
    >
      <!-- prettier-ignore -->
      <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/></svg>

      Peça agora

      {{ phoneNumber }}
    </a>

    <a
      class="elevation-3 flex h-[48px] w-[48px] items-center justify-center rounded-full bg-whatsapp-500 xl:absolute xl:bottom-12 xl:right-4 xl:h-[56px] xl:w-[56px]"
      [href]="
        'https://api.whatsapp.com/send?phone=551141182971&text=' +
        whatsAppWelcome
      "
      target="_blank"
      (click)="clickedWhatsApp.emit()"
    >
      <!-- prettier-ignore -->
      <svg width="32" height="33" fill="none" style="zoom: 0.8"><path d="M27.326 4.674C24.325 1.657 20.323 0 16.061 0 7.286 0 .137 7.148.13 15.924c0 2.811.733 5.545 2.123 7.966L0 32.145l8.447-2.215a15.94 15.94 0 0 0 7.614 1.94h.008c8.775 0 15.924-7.148 15.931-15.931-.008-4.254-1.665-8.256-4.674-11.265zm-11.265 24.5c-2.383 0-4.712-.641-6.744-1.848l-.481-.29-5.01 1.314 1.337-4.888-.313-.504a13.18 13.18 0 0 1-2.024-7.042A13.26 13.26 0 0 1 16.069 2.688a13.18 13.18 0 0 1 9.363 3.88 13.17 13.17 0 0 1 3.872 9.363c-.008 7.309-5.949 13.243-13.243 13.243zm7.263-9.913c-.397-.198-2.352-1.161-2.719-1.298-.366-.13-.634-.199-.893.199-.267.397-1.031 1.298-1.26 1.558-.229.267-.466.298-.863.099s-1.68-.619-3.2-1.978c-1.184-1.054-1.978-2.36-2.215-2.757-.229-.397-.023-.611.176-.81.176-.176.397-.466.596-.695s.267-.397.397-.664.069-.496-.03-.695-.894-2.161-1.23-2.956c-.321-.779-.649-.672-.894-.68-.229-.015-.496-.015-.764-.015s-.695.099-1.062.496-1.39 1.359-1.39 3.322 1.428 3.849 1.627 4.117 2.803 4.284 6.797 6.011c.947.412 1.688.657 2.268.84.955.305 1.818.26 2.505.16.764-.115 2.352-.962 2.688-1.894.328-.932.328-1.726.229-1.894s-.367-.267-.764-.466z" fill="#fff"/></svg>

      <span class="sr-only">WhatsApp</span>
    </a>
  </div>
</div>
