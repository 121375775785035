import { BehaviorSubject, combineLatest } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InterfaceService {
  /* ------------------------------------------------------------------------ */
  /* Private ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Booleans --------------------------------------------------------------- */
  private hideCheckPricesOnScrollBS = new BehaviorSubject<boolean>(false);
  private isEditCityModeBS = new BehaviorSubject<boolean>(false);
  private isEditVehicleModeBS = new BehaviorSubject<boolean>(false);
  private isHeavyVehiclesModeBS = new BehaviorSubject<boolean>(false);
  private liftResumeBS = new BehaviorSubject<boolean>(false);
  private menuIsOpenBS = new BehaviorSubject<boolean>(false);
  private renderCheckPricesBS = new BehaviorSubject<boolean>(false);
  private showScheduleBS = new BehaviorSubject<boolean>(false);
  private showSpinningBS = new BehaviorSubject<boolean>(false);
  private whatsAppCTAIsAboveBS = new BehaviorSubject<boolean>(false);

  // Bottom sheets
  private showBottomSheetTimelineBS = new BehaviorSubject<boolean>(false);

  // Dialogs
  // Dialogs - Home
  private showDialogBatteryUnavailableBS = new BehaviorSubject<boolean>(false);
  private showDialogCityInactiveBS = new BehaviorSubject<boolean>(false);
  private showDialogCityUnavailableBS = new BehaviorSubject<boolean>(false);
  private showDialogComplexExchangeBS = new BehaviorSubject<boolean>(false);
  private showDialogServiceUnavailableBS = new BehaviorSubject<boolean>(false);

  // Dialogs - Steps
  private showDialogBatteryUselessBS = new BehaviorSubject<boolean>(false);
  private showDialogDifferentCitiesBS = new BehaviorSubject<boolean>(false);
  private showDialogTermsBS = new BehaviorSubject<boolean>(false);

  // Dialogs - Delivery
  private showDialogCancelOrderBS = new BehaviorSubject<boolean>(false);
  private showDialogEditOrderBS = new BehaviorSubject<boolean>(false);
  private showDialogUnusableBatteryBS = new BehaviorSubject<boolean>(false);

  // Dropdowns
  private showSelectCityDropdownBS = new BehaviorSubject<boolean>(false);
  private showSelectVehicleDropdownBS = new BehaviorSubject<boolean>(false);

  // Modals
  private showSelectCityModalBS = new BehaviorSubject<boolean>(false);
  private showSelectVehicleModalBS = new BehaviorSubject<boolean>(false);

  /* Strings ---------------------------------------------------------------- */
  private itemTypeBS = new BehaviorSubject<'date' | 'time' | null>(null);

  /* ------------------------------------------------------------------------ */
  /* Public ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Booleans --------------------------------------------------------------- */
  public hideCheckPricesOnScroll$ = this.hideCheckPricesOnScrollBS.asObservable();
  public isEditCityMode$ = this.isEditCityModeBS.asObservable();
  public isEditVehicleMode$ = this.isEditVehicleModeBS.asObservable();
  public isHeavyVehiclesMode$ = this.isHeavyVehiclesModeBS.asObservable();
  public liftResume$ = this.liftResumeBS.asObservable();
  public menuIsOpen$ = this.menuIsOpenBS.asObservable();
  public renderCheckPrices$ = this.renderCheckPricesBS.asObservable();
  public showSchedule$ = this.showScheduleBS.asObservable();
  public showSpinning$ = this.showSpinningBS.asObservable();
  public whatsAppCTAIsAbove$ = this.whatsAppCTAIsAboveBS.asObservable();

  // Bottom sheets
  public showBottomSheetTimeline$ = this.showBottomSheetTimelineBS.asObservable();

  // Dialogs
  // Dialogs - Home
  public showDialogBatteryUnavailable$ = this.showDialogBatteryUnavailableBS.asObservable();
  public showDialogCityInactive$ = this.showDialogCityInactiveBS.asObservable();
  public showDialogCityUnavailable$ = this.showDialogCityUnavailableBS.asObservable();
  public showDialogComplexExchange$ = this.showDialogComplexExchangeBS.asObservable();
  public showDialogServiceUnavailable$ = this.showDialogServiceUnavailableBS.asObservable();

  // Dialogs - Steps
  public showDialogBatteryUseless$ = this.showDialogBatteryUselessBS.asObservable();
  public showDialogDifferentCities$ = this.showDialogDifferentCitiesBS.asObservable();
  public showDialogTerms$ = this.showDialogTermsBS.asObservable();

  // Dialogs - Delivery
  public showDialogCancelOrder$ = this.showDialogCancelOrderBS.asObservable();
  public showDialogEditOrder$ = this.showDialogEditOrderBS.asObservable();
  public showDialogUnusableBattery$ = this.showDialogUnusableBatteryBS.asObservable();

  // Dropdowns
  public showSelectCityDropdown$ = this.showSelectCityDropdownBS.asObservable();
  public showSelectVehicleDropdown$ = this.showSelectVehicleDropdownBS.asObservable();

  // Modals
  public showSelectCityModal$ = this.showSelectCityModalBS.asObservable();
  public showSelectVehicleModal$ = this.showSelectVehicleModalBS.asObservable();

  public combinedFlags$ = combineLatest([
    this.liftResume$,
    this.menuIsOpen$,
    this.showBottomSheetTimeline$,
    this.showDialogBatteryUnavailable$,
    this.showDialogCityInactive$,
    this.showDialogCityUnavailable$,
    this.showDialogComplexExchange$,
    this.showDialogServiceUnavailable$,
    this.showDialogBatteryUseless$,
    this.showDialogDifferentCities$,
    this.showDialogTerms$,
    this.showDialogCancelOrder$,
    this.showDialogEditOrder$,
    this.showDialogUnusableBattery$,
    this.showSelectCityModal$,
    this.showSelectVehicleModal$,
  ]);

  /* Strings ---------------------------------------------------------------- */
  public itemType$ = this.itemTypeBS.asObservable();

  /* ------------------------------------------------------------------------ */
  /* Methods ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Booleans --------------------------------------------------------------- */
  public updateHideCheckPricesOnScroll(value: boolean): void {
    this.hideCheckPricesOnScrollBS.next(value);
  }

  public updateIsEditCityMode(value: boolean): void {
    this.isEditCityModeBS.next(value);
  }

  public updateIsEditVehicleMode(value: boolean): void {
    this.isEditVehicleModeBS.next(value);
  }

  public updateIsHeavyVehiclesMode(value: boolean): void {
    this.isHeavyVehiclesModeBS.next(value);
  }

  public updateLiftResume(value: boolean): void {
    this.liftResumeBS.next(value);
  }

  public updateMenuIsOpen(value: boolean): void {
    this.menuIsOpenBS.next(value);
  }

  public updateRenderCheckPrices(value: boolean): void {
    this.renderCheckPricesBS.next(value);
  }

  public updateShowSchedule(value: boolean): void {
    this.showScheduleBS.next(value);
  }

  public updateShowSpinning(value: boolean): void {
    this.showSpinningBS.next(value);
  }

  public updateWhatsAppCTAIsAbove(value: boolean): void {
    this.whatsAppCTAIsAboveBS.next(value);
  }

  // Bottom sheets
  public updateShowBottomSheetTimeline(value: boolean): void {
    this.showBottomSheetTimelineBS.next(value);
  }

  // Dialogs
  // Dialogs - Home
  public updateShowDialogBatteryUnavailable(value: boolean): void {
    this.showDialogBatteryUnavailableBS.next(value);
  }

  public updateShowDialogCityInactive(value: boolean): void {
    this.showDialogCityInactiveBS.next(value);
  }

  public updateShowDialogCityUnavailable(value: boolean): void {
    this.showDialogCityUnavailableBS.next(value);
  }

  public updateShowDialogComplexExchange(value: boolean): void {
    this.showDialogComplexExchangeBS.next(value);
  }

  public updateShowDialogServiceUnavailable(value: boolean): void {
    this.showDialogServiceUnavailableBS.next(value);
  }

  // Dialogs - Steps
  public updateShowDialogBatteryUseless(value: boolean): void {
    this.showDialogBatteryUselessBS.next(value);
  }

  public updateShowDialogDifferentCities(value: boolean): void {
    this.showDialogDifferentCitiesBS.next(value);
  }

  public updateShowDialogTerms(value: boolean): void {
    this.showDialogTermsBS.next(value);
  }

  // Dialogs - Delivery
  public updateShowDialogCancelOrder(value: boolean): void {
    this.showDialogCancelOrderBS.next(value);
  }

  public updateShowDialogEditOrder(value: boolean): void {
    this.showDialogEditOrderBS.next(value);
  }

  public updateShowDialogUnusableBattery(value: boolean): void {
    this.showDialogUnusableBatteryBS.next(value);
  }

  // Dropdowns
  public updateShowSelectCityDropdown(value: boolean): void {
    this.showSelectCityDropdownBS.next(value);
  }

  public updateShowSelectVehicleDropdown(value: boolean): void {
    this.showSelectVehicleDropdownBS.next(value);
  }

  // Modals
  public updateShowSelectCityModal(value: boolean): void {
    this.showSelectCityModalBS.next(value);
  }

  public updateShowSelectVehicleModal(value: boolean): void {
    this.showSelectVehicleModalBS.next(value);
  }

  public updateAllBottomSheetsDialogsAndModalsAndTheMenu(value: boolean): void {
    this.updateLiftResume(value);
    this.updateMenuIsOpen(value);

    this.updateShowBottomSheetTimeline(value);

    this.updateShowDialogBatteryUnavailable(value);
    this.updateShowDialogCityInactive(value);
    this.updateShowDialogCityUnavailable(value);
    this.updateShowDialogComplexExchange(value);
    this.updateShowDialogServiceUnavailable(value);

    this.updateShowDialogBatteryUseless(value);
    this.updateShowDialogDifferentCities(value);
    this.updateShowDialogTerms(value);

    this.updateShowDialogCancelOrder(value);
    this.updateShowDialogEditOrder(value);
    this.updateShowDialogUnusableBattery(value);

    this.updateShowSelectCityModal(value);
    this.updateShowSelectVehicleModal(value);
  }

  public closeAllDropdowns(): void {
    this.updateShowSelectCityDropdown(false);
    this.updateShowSelectVehicleDropdown(false);
  }

  /* Strings ---------------------------------------------------------------- */
  public updateItemType(itemType: 'date' | 'time' | null): void {
    this.itemTypeBS.next(itemType);
  }
}
