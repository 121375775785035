import { Battery } from 'src/app/_interfaces/battery';
import { CityDetails } from 'src/app/_interfaces/city-details';
import { SearchableVehicle } from 'src/app/_interfaces/searchable-vehicle';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-battery-unavailable',
  templateUrl: './dialog-battery-unavailable.component.html',
  styleUrls: ['./dialog-battery-unavailable.component.scss'],
})
export class DialogBatteryUnavailableComponent {
  @Input() selectedBattery: Battery;
  @Input() selectedCity: CityDetails;
  @Input() selectedVehicle: SearchableVehicle;

  @Input() show: boolean;

  @Output() selectAnotherCity = new EventEmitter();
  @Output() searchRetailer = new EventEmitter();

  constructor() {
    this.selectedBattery = {} as Battery;
    this.selectedCity = {} as CityDetails;
    this.selectedVehicle = {} as SearchableVehicle;

    this.show = false;
  }

  public handleSelectAnotherCity(): void {
    this.selectAnotherCity.emit();
  }

  public handleSearchRetailer(): void {
    this.searchRetailer.emit();
  }
}
