<div
  class="fixed left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-primary-500/15"
  [ngClass]="{
    hidden: !show
  }"
>
  <!-- prettier-ignore -->
  <svg class="w-16 h-16" viewBox="0 0 200 200"><circle fill="#cf9202" stroke="#cf9202" stroke-width="2" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="0.8" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#cf9202" stroke="#cf9202" stroke-width="2" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="0.8" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#cf9202" stroke="#cf9202" stroke-width="2" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="0.8" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>

  <span class="sr-only">Carregando…</span>
</div>
