import { CityDetails } from 'src/app/_interfaces/city-details';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-city-inactive',
  templateUrl: './dialog-city-inactive.component.html',
  styleUrls: ['./dialog-city-inactive.component.scss'],
})
export class DialogCityInactiveComponent {
  @Input() selectedCity: CityDetails;

  @Input() show: boolean;

  @Output() selectAnotherCity = new EventEmitter();
  @Output() searchRetailer = new EventEmitter();

  constructor() {
    this.selectedCity = {} as CityDetails;

    this.show = false;
  }

  public handleSelectAnotherCity(): void {
    this.selectAnotherCity.emit();
  }

  public handleSearchRetailer(): void {
    this.searchRetailer.emit();
  }
}
