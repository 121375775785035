<app-toolbar
  [hideCheckPricesOnScroll]="hideCheckPricesOnScroll$"
  [menuIsOpen]="menuIsOpen$"
  [renderCheckPrices]="renderCheckPrices$"
  [phoneNumber]="phoneNumber$"
  (clickedCheckPrices)="handleClickedCheckPrices(); scrollToTop()"
  (clickedHeaderItem)="
    sendEvent('header item click', { button: 'step', $event })
  "
  (clickedMenuItem)="sendEvent('menu item click', $event)"
  (clickedPhone)="
    sendEvent('click phone', {
      clicked_phone: 'header',
      phone_number: phoneNumber$
    })
  "
  (menuToggled)="handleMenuToggled($event)"
/>

<router-outlet />

<app-baseboard
  [phoneNumber]="phoneNumber$"
  (clickedMenuItem)="sendEvent('footer item click', $event)"
  (clickedPhone)="
    sendEvent('click phone', {
      clicked_phone: 'footer',
      phone_number: phoneNumber$
    })
  "
  (clickedSocialItem)="sendEvent('social item click', $event)"
/>

<app-floating
  [menuIsOpen]="menuIsOpen$"
  [renderCheckPrices]="renderCheckPrices$"
  [whatsAppCTAIsAbove]="whatsAppCTAIsAbove$"
  [phoneNumber]="phoneNumber$"
  [whatsAppWelcome]="whatsAppWelcome$"
  (clickedOrderNow)="
    sendEvent('click phone', {
      button: 'phone - mobile',
      clicked_phone: 'floating',
      phone_number: phoneNumber$
    })
  "
  (clickedWhatsApp)="sendEvent('click floating whatsapp')"
/>

<app-spinning [show]="showSpinning$" />

<app-dialog-battery-unavailable
  [selectedBattery]="selectedBattery$"
  [selectedCity]="selectedCity$"
  [selectedVehicle]="selectedVehicle$"
  [show]="showDialogBatteryUnavailable$"
  (selectAnotherCity)="handleSelectAnotherCity()"
  (searchRetailer)="handleSearchRetailer()"
/>

<app-dialog-city-inactive
  [selectedCity]="selectedCity$"
  [show]="showDialogCityInactive$"
  (selectAnotherCity)="handleSelectAnotherCity()"
  (searchRetailer)="handleSearchRetailer()"
/>

<app-dialog-city-unavailable
  [selectedCity]="selectedCity$"
  [show]="showDialogCityUnavailable$"
  (selectAnotherCity)="handleSelectAnotherCity()"
  (searchRetailer)="handleSearchRetailer()"
/>

<app-dialog-complex-exchange
  [selectedVehicle]="selectedVehicle$"
  [show]="showDialogComplexExchange$"
  (close)="handleCloseDialogComplexExchange()"
  (searchRetailer)="handleSearchRetailer()"
/>

<app-dialog-service-unavailable
  [show]="showDialogServiceUnavailable$"
  (close)="handleCloseDialogServiceUnavailable()"
  (talkViaWhatsApp)="handleTalkViaWhatsApp()"
/>

<div
  id="google-merchants-metadata"
  itemscope
  itemtype="http://schema.org/Product"
  style="display: none"
  *ngIf="googleMerchantsProduct$.product"
>
  <span>{{ googleMerchantsProduct$.product?.offerId }}</span>

  <span itemprop="description">
    {{ googleMerchantsProduct$.product?.description }}
  </span>

  <span itemprop="image">{{ googleMerchantsProduct$.product?.imageLink }}</span>

  <span itemprop="name">{{ googleMerchantsProduct$.product?.title }}</span>

  <div itemscope itemprop="offers" itemtype="http://schema.org/Offer">
    <meta
      itemprop="priceCurrency"
      content="{{ googleMerchantsProduct$.product?.price?.currency }}"
    />

    <span itemprop="price">
      R$ {{ googleMerchantsProduct$.product?.price?.value }}
    </span>
  </div>

  <span>{{ googleMerchantsProduct$.product?.availability }}</span>

  <span>{{ googleMerchantsProduct$.product?.brand }}</span>

  <span>{{ googleMerchantsProduct$.product?.channel }}</span>

  <span>{{ googleMerchantsProduct$.product?.condition }}</span>

  <span>{{ googleMerchantsProduct$.product?.googleProductCategory }}</span>

  <span>{{ googleMerchantsProduct$.product?.mpn }}</span>
</div>
